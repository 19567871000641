import axios from "axios";
import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import banner_image from "../images/inner-banner-6.jpg";
import { FormInput } from "./FormInput";

export default function Career() {
  
  const [file, setFile] = useState()

  function handleChange(event) {
    setFile(event.target.files[0])
  }

  const [careerForm, setCareertForm] = useState({
    name:'',
    email:'',
    mobile:'',
    message:''
  });

  const [careerValidate, setCareerValidate] = useState({
    name:false,
    email:false,
    mobile:false,
    formStatus: false
  });

  const API_PATH = 'https://dynamicair.in/api/careers/index.php'; 

  
  const handleFormData = (data) => {
    setCareertForm({...careerForm, ...data});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('name', careerForm.name);
    formData.append('email', careerForm.email);
    formData.append('mobile', careerForm.mobile);
    formData.append('message', careerForm.message);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(API_PATH, formData, config).then((response) => {
      window.location = "/thanks";
    });
    return false;
  }  
  return (
    <div>

<MetaTags>
            <title>Careers</title>
            <meta name="description" content="Dynamic Air utilizes fiber laser technology, which cuts faster and more accurately than traditional lasers." /> 
            <meta name="keyword" content="" /> 
    </MetaTags>

      <section className="inner-banner">
        <img src={banner_image} />
        <Col className="banner-sector">
          <Container>
            <Row>
              <Col className="ban-cnt">
                <h1
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Careers
                </h1>
                <ul
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li> / </li>
                  <li> Careers </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
      </section>

      <section className="section about-section">
        <Container>
          <Row className="align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="common-title">
                <h2>Careers</h2>
              </div>
              <div className="common-second-title">
                <h3>Join the team of tomorrow</h3>
              </div>
              <div className="common-third-title">
                <h4>
                  At Dynamic Air, you don’t just have a job or one of many
                  careers you have a home for your passion and are surrounded by
                  a team that supports and celebrates you.{" "}
                </h4>
              </div>
              <div className="common-para">
                <p>
                  We take a people-centric approach to manufacturing with a
                  collaborative and energetic culture, redefining what it looks
                  and feels like to work in manufacturing. Our team is composed
                  of smart, dynamic individuals who are leaders in innovation,
                  engineering, business, automation, sales, and design.<br></br>
                  <br></br>
                  We are here to make dreams into reality – whether it’s turning
                  a customer’s vision into tangible, well-built parts and
                  assemblies or coaching our team members to discover their
                  life’s purpose. We reward performance and provide our team
                  members with job opportunities that encourage individual
                  growth and job satisfaction.
                </p>
              </div>
            </div>

            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="career-frm">
                <Form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <h4>Apply Now</h4>
                    <label>Name *</label>
                    <input required={true} type="text" label="name"
                            class="form-control"
                            value={careerForm.name}
                            onChange={(e) => handleFormData({ name: e.target.value })}
                            placeholder=" " />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                  <label>E-mail Id *</label>
                  <input required={true} type="email" 
                            class="form-control"
                            value={careerForm.email}
                           onChange={(e) => handleFormData({ email: e.target.value })} label="email" placeholder=" " />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                  <label>Phone Number *</label>
                  <input required={true} type="tel" maxlength="12"
                            class="form-control"
                            // pattern="[789][0-9]{9}"
                            value={careerForm.mobile}
                            onChange={(e) =>  handleFormData({ mobile: e.target.value })} label="phone number" placeholder=" " />
                  </div> 
                  <div className="col-sm-12 col-md-12 col-lg-12">
                  <label>Resume *</label>
                  <input required={true} type="file" accept=".pdf,.doc,.docx"
                            class="form-control"
                            onChange={handleChange} label="phone number" placeholder=" " />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                  <label>Meassage</label>
                  <textarea className="form-control" placeholder=" "  
                          value={careerForm.message}
                          onChange={(e) => handleFormData({ message: e.target.value })} 
                          >
                          </textarea>
                    <button type="submit">Submit</button>
                  </div>
                </div>
                </Form>
               
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
}
