import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import banner_image from "../images/inner-banner-6.jpg";
import { FormInput } from "./FormInput";

export default function CompanyProfile() {
  return (
    <div>
      <section className="inner-banner">
        <img src={banner_image} />
        <Col className="banner-sector">
          <Container>
            <Row>
              <Col className="ban-cnt">
                <h1
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Success
                </h1> 
              </Col>
            </Row>
          </Container>
        </Col>
      </section>
      <section className="section about-section">
        <Container>
          <Row className="align-items-center">
            <div
              className="col-lg-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            > 
              <div className="common-second-title">
                <h3>Thank You</h3>
              </div>
              <div className="common-third-title">
                <h4>
                Thanks for spending your valuable time.<br></br>
We will reach you soon... 
                </h4>
              </div>
              
            </div> 
          </Row>
        </Container>
      </section>
  
    </div>
  );
}
