import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import banner_image from '../images/inner-banner-1.jpg';
import Addressmap from './Addressmap';
import { FaRegBuilding } from "react-icons/fa";
import { RiBuilding4Line } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import axios from "axios";
import { Form, Helper, Alert, FormSubmitButton } from "./customStyle";

export const isText = RegExp(/^[A-Z ]{3,}$/i);
export const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
export const isPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/);

export default function Contactus() {

  const [contactForm, setContactForm] = useState({
    name:'',
    email:'',
    mobile:'',
    location:'',
    message:''
  });

  const [contactValidate, setContactValidate] = useState({
    name:false,
    email:false,
    mobile:false,
    location:false,
    formStatus: false
  });

  const API_PATH = 'https://dynamicair.in/api/contact/index.php'; 

  const handleFormData = (data) => {
    setContactForm({...contactForm, ...data});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: contactForm
    })
    .then(result => {
      if(result.data.sent){
        window.location = "/thanks";
      } else {
        console.log('check email issue');
      }
    })
    .catch(error => console.log(error));
  }

  return (
    <div>
<MetaTags>
  <title>Contact Us</title>
  <meta name="description" content="Dynamic Air utilizes fiber laser technology, which cuts faster and more accurately than traditional lasers." /> 
  <meta name="keyword" content="" /> 
</MetaTags>
      <section className='inner-banner'>
        <img src={banner_image} />
        <Col className='banner-sector'>
          <Container>
            <Row>
              <Col className='ban-cnt'>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
                  Contact Us
                </h1>
                <ul data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300">
                  <li><Link to='/'>Home</Link></li>
                  <li> / </li>
                  <li className="active">Contact Us</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
      </section>

      <section className="contact-us">
        <Container>
          <Row>
            <div className='cont-usall'>
              <Row>
                <div className='col-lg-7 bg-white'>
                  <div className='get-intoch' data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
                    <div className="common-second-title"><h3>Get in Touch</h3></div>
                    <Form onSubmit={handleSubmit} method="POST">
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <input required={true} type="text" label="name"
                            class="form-control"
                            value={contactForm.name}
                            onChange={(e) => handleFormData({ name: e.target.value })}
                            placeholder="Name *" />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <input required={true} type="email" 
                            class="form-control"
                            value={contactForm.email}
                           onChange={(e) => handleFormData({ email: e.target.value })} label="email" placeholder="Email Id *" />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <input required={true} type="text" onKeypress="CheckNumericKeyInfo(event.keyCode, event.which)" maxlength="12"
                            class="form-control"
                            pattern="[789][0-9]{9}"
                            value={contactForm.mobile}
                            onChange={(e) =>  handleFormData({ mobile: e.target.value })} label="phone number" placeholder="Mobile Number *" />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <input required={false} type="text" 
                          class="form-control"
                          value={contactForm.location}
                          onChange={(e) => handleFormData({ location: e.target.value })} label="location" placeholder="Location" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <textarea className="form-control" placeholder="Enter your message"  
                          value={contactForm.message}
                          onChange={(e) => handleFormData({ message: e.target.value })} 
                          >
                          </textarea>
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className='col-lg-5 p-0'>
                  <div className='adress-layr' data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
                    <div className="common-second-title white"><h3>Reach Us</h3></div>
                    <ul>
                      <li><FaRegBuilding />
                        <span><b>Unit - I</b><br/>No.4, Boopathy Nagar, <br></br>
                          Industrial Estate, <br></br>
                          Keelkattalai, Chennai - 600117.</span></li>
                      <li><RiBuilding4Line />
                        <span><b>Unit - II</b><br/>No.123, Satharai Village <br></br>
                          Near Mappedu Koot Road, <br></br>
                          Thiruvallur District, Chennai - 631402.</span></li>
                      <li><FiPhoneCall />
                        <span>044 2247445 / 46 / 48 | +91 8925202444</span></li>
                      <li><AiOutlineMail />
                        <span>marketing@dynamicair.in</span></li>
                    </ul>
                  </div>
                </div>

              </Row>
            </div>

          </Row>
        </Container>
      </section>

      <Addressmap />

    </div>
  )
}
